import React from 'react'
import {
  DisplayObserver,
  parseAuxParams,
  EventWrapper,
  useAnalytics
} from '@monetization/hpaip-ui-shared-components'
import { EventNames, WrapperType } from './AnaysticsConstants'
const WrapperMap = {
  [WrapperType.EventWrapper]: EventWrapper,
  [WrapperType.DisplayObserver]: DisplayObserver
}
interface AnalyticsObserverProps {
  wrapper: WrapperType
  eventName: EventNames
  printerData?: {
    printer?: {
      subscriptionId?: string
      entityStartDate?: string
    }
  }
  children?: React.ReactNode
}
const AnalysticsObserver: React.FC<AnalyticsObserverProps> = ({
  wrapper,
  eventName,
  printerData,
  children
}) => {
  const { events } = useAnalytics()
  const eventHandler = events[eventName]
  const eventProps = eventHandler(
    parseAuxParams({
      subscriptionId: printerData?.printer?.subscriptionId,
      subscriptionStartDate: printerData?.printer?.entityStartDate
    })
  )
  const WrapperComponent = WrapperMap[wrapper]
  return <WrapperComponent eventProps={eventProps}>{children}</WrapperComponent>
}
export default AnalysticsObserver
