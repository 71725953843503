import React, { useEffect, useState } from 'react'
import {
  useCommonNotification,
  useFlags,
  Subscription
} from '@monetization/hpaip-notification-rules-react'
import { Page } from '../Page'
import { CommonProps } from 'src/utils/common-util'
import { Analytics } from '../Analytics'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
interface Props {
  subscriptionId: string
  subscriptionsData: Subscription
  commonProps: CommonProps
  refreshPrinterSelection: any
  setIndex: React.Dispatch<React.SetStateAction<number>>
  index: number
}
const InitialComponent: React.FunctionComponent<Props> = ({
  subscriptionId,
  subscriptionsData,
  commonProps,
  refreshPrinterSelection,
  setIndex,
  index
}) => {
  const { t } = commonProps
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const flags = useFlags()
  const flagObject = {
    enablePaperOnPaaS: flags?.enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation: flags?.enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment: flags?.enableAddPaperPostEnrollment
  }

  const data = useCommonNotification(
    'overview',
    subscriptionId,
    flagObject,
    null,
    null
  )

  useEffect(() => {
    if (data?.printerData?.error == null) {
      setLoading(false)
      setError(false)
    } else {
      setLoading(false)
      setError(true)
    }
  }, [data?.printerData?.error])

  return (
    <>
      {data?.printerData?.printer ? (
        <>
          <Analytics analytics={commonProps?.analytics} printerProps={data}>
            <Page
              setIndex={setIndex}
              index={index}
              printerProps={data}
              subscriptionsData={subscriptionsData}
              commonProps={commonProps}
              refreshPrinterSelection={refreshPrinterSelection}
            />
          </Analytics>
        </>
      ) : (
        <LoadingHandler
          loading={loading}
          error={error}
          customError={
            <ErrorSection
              t={t}
              title="Something went wrong"
              description="Your account information did not load."
              buttonText="Try again"
            />
          }
        ></LoadingHandler>
      )}
    </>
  )
}

export default InitialComponent
