import { UsageDataSummary } from '@monetization/hpaip-notification-rules-react'
import React from 'react'
import { ProgressWrapper } from '../styles'
import { AdditionalProgress } from './AdditionalProgress'
import { PlanProgress } from './PlanProgress'
import { RolloverProgress } from './RolloverProgress'
import { CommonProps } from 'src/utils/common-util'
interface SummaryProgressProps {
  planDetails: UsageDataSummary
  commonProps: CommonProps
  printerData: any
}

export function isNil<T>(value: T): value is null {
  return value === null || value === undefined
}

export const SummaryProgress: React.FC<SummaryProgressProps> = ({
  planDetails,
  commonProps,
  printerData
}) => {
  const {
    printPlanPageAllowancePrinted,
    printPlanPageAllowance,
    printPlanRolloverPageAllowancePrinted,
    printPlanRolloverPageAllowance,
    printPlanOveragePagesPrinted,
    printPlanOveragePageBlocks,
    monthlyOveragePageBlockSize
  } = planDetails

  const showPlanProgress =
    !isNil(printPlanPageAllowancePrinted) && !isNil(printPlanPageAllowance)

  const showRolloverProgress =
    !isNil(printPlanRolloverPageAllowancePrinted) &&
    !isNil(printPlanRolloverPageAllowance) &&
    printPlanRolloverPageAllowance > 0

  const showAdditionalProgress =
    printPlanOveragePageBlocks && monthlyOveragePageBlockSize

  return (
    <ProgressWrapper>
      {showPlanProgress && (
        <PlanProgress
          printPlanPageAllowancePrinted={printPlanPageAllowancePrinted}
          printPlanPageAllowance={printPlanPageAllowance}
          commonProps={commonProps}
          printerData={printerData}
        />
      )}
      {showRolloverProgress && (
        <RolloverProgress
          printPlanRolloverPageAllowancePrinted={
            printPlanRolloverPageAllowancePrinted
          }
          printPlanRolloverPageAllowance={printPlanRolloverPageAllowance}
          commonProps={commonProps}
          printerData={printerData}
        />
      )}
      {!!showAdditionalProgress && (
        <AdditionalProgress
          printPlanOveragePagesPrinted={printPlanOveragePagesPrinted}
          printPlanOveragePageBlocks={printPlanOveragePageBlocks}
          monthlyOveragePageBlockSize={monthlyOveragePageBlockSize}
          commonProps={commonProps}
          printerData={printerData}
        />
      )}
    </ProgressWrapper>
  )
}
