export enum WrapperType {
  EventWrapper = 'EventWrapper',
  DisplayObserver = 'DisplayObserver'
}
export enum EventNames {
  OverviewDisplayed = 'OverviewDisplayed',
  ClickManagePayments = 'ClickManagePayments',
  ClickManagePaymentsMethod = 'ClickManagePaymentsMethod',
  ClickManageAddresses = 'ClickManageAddresses',
  ClickChangePlan = 'ClickChangePlan',
  ClickRecyclingEnvelope = 'ClickRecyclingEnvelope',
  DisplayedCartridgeStatusAlert = 'DisplayedCartridgeStatusAlert',
  ClickCartridgeStatusAlert = 'ClickCartridgeStatusAlert',
  DisplayedCartridgeStatusError = 'DisplayedCartridgeStatusError',
  ClickCartridgeStatusError = 'ClickCartridgeStatusError',
  ClickMonthlySummary = 'ClickMonthlySummary',
  ClickBrowsePlans = 'ClickBrowsePlans',
  ClickViewPrintHistory = 'ClickViewPrintHistory',
  DisplayedPaymentIssueResolved = 'DisplayedPaymentIssueResolved',
  DisplayedPaymentIsOverdue = 'DisplayedPaymentIsOverdue',
  ClickLearnMore = 'ClickLearnMore',
  DisplayedSubscriptionSuspended = 'DisplayedSubscriptionSuspended',
  ClickCitizensPay = 'ClickCitizensPay',
  ClickContactSupport = 'ClickContactSupport',
  DisplayedSubscriptionNoLongerActive = 'DisplayedSubscriptionNoLongerActive',
  DisplayedLoadingDataIssueNotification = 'DisplayedLoadingDataIssueNotification',
  DisplayedSubscriptionHasBeenCancelled = 'DisplayedSubscriptionHasBeenCancelled',
  ClickNotificationSupportLink = 'ClickNotificationSupportLink',
  ClickDismissNotification = 'ClickDismissNotification',
  ClickViewAllSubscriptions = 'ClickViewAllSubscriptions',
  ClickCancelSubscription = 'ClickCancelSubscription',
  ClickEditPrinterName = 'ClickEditPrinterName',
  ClickSupport = 'ClickSupport',
  DisplayedCancelationError = 'DisplayedCancelationError',
  ClickDismissCancelationError = 'ClickDismissCancelationError',
  CreditAvailableTooltip = 'CreditAvailableTooltip',
  PrintPlanTooltip = 'PrintPlanTooltip'
}
