import {
  CardError,
  CitizensBalance,
  formatDate,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  formatCurrency,
  navigationLinks
} from '@monetization/hpaip-notification-rules-react'
import React, { useEffect, useState } from 'react'
import { useLazyGetBillingDetails } from '../../api/services'
import Images from '../../assets/images'
import {
  CardExternalLink,
  ExternalLinkIcon,
  PrearrivalContent,
  CardExternalLinkPreArival,
  ExternalLinkIconPreArival
} from '../styles'
import {
  CitizensPayIcon,
  CitizensStaticHeightCard,
  CardErrorContent
} from './CitizensBalanceCard.styles'
import AnalysticsObserver from '../Analytics/AnalysticsObserver'
import { EventNames, WrapperType } from '../Analytics/AnaysticsConstants'

const CitizensBalanceCard: React.FunctionComponent<any> = ({
  hasInvoicedData,
  printerData,
  commonProps
}) => {
  const { authProvider, t, localization } = commonProps
  const [isPreArrivalState, setPreArrvivalState] = useState<boolean>(false)
  const billingDate = new Date()
  const formatedMonthYearDate = formatDate(localization.locale, billingDate)
  const { citizensBankAuth } = navigationLinks

  const [
    fetchBillingDetails,
    { data, loading: isBillingDetailsLoading, error: billingDetailsError }
  ] = useLazyGetBillingDetails()

  const IsSettled = billingDetailsError?.message?.includes('no setteled')

  const { CurrentAccountBalanceAmount, AvailableCreditAmount } =
    data?.getCitizensBillingDetails || {
      CurrentAccountBalanceAmount: 0,
      AvailableCreditAmount: 0
    }

  useEffect(() => {
    if (!printerData?.printer?.source?.value?.orderId) return
    authProvider?.getAccessToken().then((token) => {
      fetchBillingDetails({
        variables: {
          orderId: printerData?.printer?.source?.value?.orderId
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })
    })
  }, [authProvider, fetchBillingDetails, printerData?.printer])

  useEffect(() => {
    setPreArrvivalState(!hasInvoicedData)
  }, [authProvider, printerData?.printer, hasInvoicedData])

  useEffect(() => {
    if (IsSettled) setPreArrvivalState(IsSettled)
  }, [IsSettled])

  const isLoading = printerData?.isLoading || isBillingDetailsLoading
  const shouldShowFooter = !isLoading && Boolean(billingDetailsError) === false

  const isOnErrorState =
    !isLoading &&
    (!!billingDetailsError ||
      !AvailableCreditAmount ||
      (!CurrentAccountBalanceAmount && !isPreArrivalState) ||
      isPreArrivalState)
  return (
    <CitizensStaticHeightCard
      icon={
        isPreArrivalState ? (
          <CitizensPayIcon
            src={Images.PreArrivalCitizenPayIcon}
            width="140px"
            height="18px"
            alt="Citizens Pay PreArrival"
          />
        ) : (
          <CitizensPayIcon src={Images.CitizensPayIcon} alt="Citizens Pay" />
        )
      }
      title={t('dashboard.paas.citizens-balance.title', 'Citizens balance')}
      contentPosition="bottom"
      footer={
        shouldShowFooter && (
          <CardExternalLink
            data-testid="citizens-balance-card-link"
            href={citizensBankAuth}
            target="_blank"
          >
            {t(
              'dashboard.paas.citizens-balance.manage-payments',
              'Manage payments at Citizens Pay'
            )}
            <ExternalLinkIcon />
          </CardExternalLink>
        )
      }
    >
      <LoadingHandler
        loading={isLoading}
        error={isOnErrorState}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <AnalysticsObserver
            wrapper={WrapperType.DisplayObserver}
            eventName={EventNames.DisplayedLoadingDataIssueNotification}
            printerData={printerData}
          >
            <CardError
              forceHeight={false}
              icon={
                <CitizensPayIcon
                  src={Images.PreArrivalCitizenPayIcon}
                  alt="Citizens Pay"
                  width="140px"
                  height="18px"
                />
              }
              texts={
                isPreArrivalState
                  ? [
                      <>
                        <PrearrivalContent>
                          {t(
                            'dashboard.paas.citizens-balance.prearrival',
                            'Citizens balance will display here soon'
                          )}
                        </PrearrivalContent>
                        <CardExternalLinkPreArival
                          data-testid="citizens-balance-card-link"
                          href={citizensBankAuth}
                          target="_blank"
                        >
                          {t(
                            'dashboard.paas.citizens-balance.manage-payments',
                            'Manage payments at Citizens Pay'
                          )}
                          <ExternalLinkIconPreArival />
                        </CardExternalLinkPreArival>
                      </>
                    ]
                  : [
                      <>
                        <CardErrorContent>
                          {t(
                            'dashboard.paas.citizens-balance.error.title.paragraph-1',
                            'Something went wrong.'
                          )}
                        </CardErrorContent>
                        <CardErrorContent>
                          {t(
                            'dashboard.paas.citizens-balance.error.title.paragraph-2',
                            'Your Citizens Pay balance did not load.'
                          )}
                        </CardErrorContent>
                      </>,
                      <>
                        <CardErrorContent>
                          {t(
                            'dashboard.paas.citizens-balance.error.description',
                            'Please refresh the page.'
                          )}
                        </CardErrorContent>
                      </>
                    ]
              }
            />
          </AnalysticsObserver>
        }
      >
        <CitizensBalance
          t={t}
          formatedMonthYearDate={formatedMonthYearDate}
          totalBalance={formatCurrency(
            CurrentAccountBalanceAmount.toFixed(2),
            localization.locale
          )}
          creditAvailable={formatCurrency(
            AvailableCreditAmount.toFixed(2),
            localization.locale
          )}
        />
      </LoadingHandler>
    </CitizensStaticHeightCard>
  )
}

export default CitizensBalanceCard
