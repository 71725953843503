import IconPersonAssist from '@veneer/core/dist/esm/scripts/icons/icon_person_assist'
import * as React from 'react'
import {
  SupportDetailsCard,
  CardLinkWrapper,
  FooterDescription,
  SupportStyledLink
} from '../styles'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'
import AnalysticsObserver from '../Analytics/AnalysticsObserver'
import { EventNames, WrapperType } from '../Analytics/AnaysticsConstants'

const SupportCard: React.FunctionComponent<any> = ({
  commonProps,
  printerData
}) => {
  const { t } = commonProps
  return (
    <SupportDetailsCard
      icon={<IconPersonAssist size={32} color="gray12" />}
      title={t(
        'dashboard.paas.support-details-card.title',
        'HP All-In Plan Support'
      )}
      footer={
        <>
          <AnalysticsObserver
            wrapper={WrapperType.EventWrapper}
            eventName={EventNames.ClickSupport}
            printerData={printerData}
          >
            <SupportStyledLink
              target={'_blank'}
              href={'https://hp.com/all-in-plan/printer-support'}
              rel="noreferrer"
            >
              <CardLinkWrapper id="find-answer-button">
                {t(
                  'dashboard.paas.support-details-card.find-answers',
                  'Find an answer'
                )}

                <IconChevronRight />
              </CardLinkWrapper>
            </SupportStyledLink>
          </AnalysticsObserver>
          <AnalysticsObserver
            wrapper={WrapperType.EventWrapper}
            eventName={EventNames.ClickSupport}
            printerData={printerData}
          >
            <SupportStyledLink
              target={'_blank'}
              href={'https://support.hp.com/contact/all-in-plan-serial-number'}
              rel="noreferrer"
            >
              <CardLinkWrapper id="ask-by-chat-button">
                {t(
                  'dashboard.paas.support-details-card.ask-by-chat',
                  'Open live chat'
                )}

                <IconChevronRight />
              </CardLinkWrapper>
            </SupportStyledLink>
          </AnalysticsObserver>
          <AnalysticsObserver
            wrapper={WrapperType.EventWrapper}
            eventName={EventNames.ClickSupport}
            printerData={printerData}
          >
            <SupportStyledLink
              target={'_blank'}
              href={'https://support.hp.com/contact/all-in-plan-serial-number'}
              rel="noreferrer"
            >
              <CardLinkWrapper id="we-call-you-button">
                {t(
                  'dashboard.paas.support-details-card.we-call-you',
                  'Request a call'
                )}

                <IconChevronRight />
              </CardLinkWrapper>
            </SupportStyledLink>
          </AnalysticsObserver>
          <FooterDescription>
            {t(
              'dashboard.paas.support-details-card.description',
              "Can't find the answer to your urgent question?"
            )}
            <br />
            {t(
              'dashboard.paas.support-details-card.description2',
              'You can also call us on 1-888-447-0148'
            )}
          </FooterDescription>
        </>
      }
    ></SupportDetailsCard>
  )
}

export default SupportCard
