import {
  AnalyticsProvider,
  parseAuxParams,
  ShellAnalytics
} from '@monetization/hpaip-ui-shared-components'
import React, { useMemo } from 'react'
import analyticsEvents from '../../utils/analyticsEvents/analyticsEvents'

interface Props {
  analytics: ShellAnalytics
  printerProps: any
}

export const Analytics: React.FC<Props> = ({
  children,
  analytics,
  printerProps
}) => {
  const auxParams = useMemo(() => {
    const params = parseAuxParams({
      subscriptionId: printerProps?.printerData?.root?.subscriptionId,
      subscriptionStartDate: printerProps?.printerData?.root?.createdAt
    })

    return params
  }, [printerProps?.printerData])

  return useMemo(
    () => (
      <AnalyticsProvider
        analytics={analytics}
        defaults={{
          screenPath: '/ReactPaasDashboardOverview/',
          activity: 'HpOnePrintMgmt-v01',
          version: '1.4.0',
          ...auxParams
        }}
        events={analyticsEvents}
      >
        {children}
      </AnalyticsProvider>
    ),
    [analytics, auxParams, children]
  )
}
