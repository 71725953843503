import { ApolloProvider } from '@apollo/client'
import ToastProvider from '@veneer/core/dist/esm/scripts/toast_container/toast_provider'
import React from 'react'
import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'
import PrinterMainComponent from '../PrinterMainComponent'
import getEnvironmentVariables from '../../helpers/getEnvironmentVariables'
import { getStack } from '../../helpers/getStack'
import useApolloClient from '../../hooks/useApolloClient'

const Root = ({
  t,
  authProvider,
  analytics,
  stack,
  localization,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const envVariables = getEnvironmentVariables(getStack(stack))
  const graphqlClient = useApolloClient(
    authProvider,
    envVariables?.variables?.MAGENTO_URL
  )
  const bizlogicId = envVariables.variables.BL_ID
  //This will be use for multiple subscription later
  // const [index, setIndex] = useState<number>(0)
  return (
    <ApolloProvider client={graphqlClient}>
      <ToastProvider>
        <PrinterMainComponent
          commonProps={{
            authProvider,
            stack,
            t,
            analytics,
            localization,
            navigation,
            bizlogicId
          }}
        />
      </ToastProvider>
    </ApolloProvider>
  )
}

export default Root
