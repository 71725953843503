import {
  parseAuxParams,
  ProgressBar,
  useAnalytics
} from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import { CUSTOM_COLORS } from '../../../__mocks__/constants/styles.constants'
import { CommonProps } from 'src/utils/common-util'
interface RolloverProgressProps {
  printPlanRolloverPageAllowancePrinted: number
  printPlanRolloverPageAllowance: number
  commonProps: CommonProps
  printerData: any
}

export const RolloverProgress: React.FC<RolloverProgressProps> = ({
  printPlanRolloverPageAllowancePrinted,
  printPlanRolloverPageAllowance,
  commonProps,
  printerData
}) => {
  const { t } = commonProps
  const { events } = useAnalytics()
  const progressNameColor = CUSTOM_COLORS.colorSkyBlue
  return (
    <ProgressBar
      t={t}
      progressNameColor={progressNameColor}
      event={events.ClickMonthlySummary({
        controlName: 'RolloverPages',
        actionAuxParams: parseAuxParams({
          subscriptionId: printerData?.printer?.subscriptionId,
          subscriptionStartDate: printerData?.printer?.entityStartDate
        }) as any
      })}
      color={
        printPlanRolloverPageAllowance <= 0
          ? 'gray1'
          : CUSTOM_COLORS.colorSkyBlue
      }
      total={printPlanRolloverPageAllowance}
      current={printPlanRolloverPageAllowancePrinted}
      label={t('usage-data-progress.rollover.label', 'Rollover pages')}
      tip={t(
        'usage-data-progress.rollover.tip',
        'Unused pages roll over to your next monthly allotment.'
      )}
    />
  )
}
