import {
  parseAuxParams,
  ProgressBar,
  useAnalytics
} from '@monetization/hpaip-ui-shared-components'
import React from 'react'
import { CUSTOM_COLORS } from '../../../__mocks__/constants/styles.constants'
import { CommonProps } from 'src/utils/common-util'

interface PlanProgressProps {
  printPlanPageAllowancePrinted: number
  printPlanPageAllowance: number
  commonProps: CommonProps
  printerData: any
}

export const PlanProgress = ({
  printPlanPageAllowancePrinted,
  printPlanPageAllowance,
  commonProps,
  printerData
}: PlanProgressProps) => {
  const { t } = commonProps
  const { events } = useAnalytics()
  const progressNameColor = CUSTOM_COLORS.colorRoyalBlue
  return (
    <ProgressBar
      t={t}
      progressNameColor={progressNameColor}
      event={events.ClickMonthlySummary({
        controlName: 'PlanPages',
        actionAuxParams: parseAuxParams({
          subscriptionId: printerData?.printer?.subscriptionId,
          subscriptionStartDate: printerData?.printer?.entityStartDate
        }) as any
      })}
      color={
        printPlanPageAllowancePrinted <= 0
          ? 'gray1'
          : CUSTOM_COLORS.colorRoyalBlue
      }
      total={printPlanPageAllowance}
      current={printPlanPageAllowancePrinted}
      label={t('usage-data-progress.plan.label', 'Plan pages')}
      tip={t(
        'usage-data-progress.plan.tip',
        'The number of pages included in your monthly plan.'
      )}
    />
  )
}
