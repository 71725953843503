import { tokens } from '@veneer/tokens'
const { royalBlue7, mediumOrchid6 } = tokens.color
export const CUSTOM_COLORS = {
  colorSkyBlue: '#1DDCE8',
  colorAliceBlue: '#F2F8FB',
  colorDeepBlue: '#0076AD',
  colorRoyalBlue: royalBlue7,
  colorPurple: mediumOrchid6,
  colorGreen: '#1c7a17'
} as const
